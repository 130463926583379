import { AuthenticatedHttp } from '../../http/axios';
import { uploadImage } from '../product-gallery';

export const uploadIfNecessary = async ({ courseId, image }) => {
  if (image instanceof File) {
    image = await uploadImage({ productID: courseId, file: image });
  }
  return image?.preview;
};

export const getCourseById = async ({ courseId }) =>
  AuthenticatedHttp.get(`/members/courses/${courseId}/`).then((response) => ({
    name: response.data.data.nome,
    coverImage: {
      preview: response.data.data.capa,
    },
    modules: response?.data?.data?.modulos
      .filter((module) => module !== null)
      .map?.((module) => ({
        id: module?.id,
        name: module?.nome,
        capa: module?.capa,
        coverImage: {
          preview: module?.capa,
        },
        order: module?.posicao,
        lessons:
          module?.aulas?.map?.((lesson) => ({
            id: lesson?.id,
            title: lesson?.nome,
            thumbnail: {
              preview: lesson?.capa,
            },
            order: lesson?.posicao,
            type: 'lesson',
            status: 'published',
          })) || [],
      })),
  }));

export const getAllCourses = async () =>
  AuthenticatedHttp.get('/members/courses/', { withCredentials: false }).then(({ data }) => data);

export const updateCourse = async ({ courseId, name, coverImage }) =>
  AuthenticatedHttp.put(`/members/courses/${courseId}/`, {
    nome: name,
    capa: coverImage ? await uploadIfNecessary({ courseId, image: coverImage }) : '',
    modulos: [],
  });
