export function truncateText(text, maxLength) {
  if (!text) return '';

  if (text?.length <= maxLength) {
    return text;
  }
  return `${text?.slice(0, maxLength - 3)}...`;
}

/**
 * Formata um número de telefone.
 * @param {string} phoneNumber - O número de telefone a ser formatado.
 * @returns {string} - O número de telefone formatado.
 */
export const formatPhoneNumber = (phoneNumber) => {
  const cleaned = phoneNumber.replace(/\D/g, '');

  const formatted = cleaned.replace(/(\d{2})(\d{5})(\d{4})/, '$1 $2-$3');
  return formatted;
};
