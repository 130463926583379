import PropTypes from 'prop-types';
import { useState, useEffect, useMemo } from 'react';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, Typography, FormHelperText, Alert, InputAdornment } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { Stack } from '@mui/system';
import { MuiTelInput } from 'mui-tel-input';
import FormProvider, { RHFCodes } from '@/components/hook-form';
import Iconify from '@/components/iconify';
import { useAuthContext } from '@/auth/useAuthContext';
import { formatPhoneNumber } from '@/utils/text';
import useVerifyModal from './useVerifyModal/useVerifyModal';

VerifyModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  isToValidateEmail: PropTypes.bool,
  isToValidateWhatsapp: PropTypes.bool,
  isToLogoutOnClose: PropTypes.bool,
  onSuccessWhatsapp: PropTypes.func,
};

export default function VerifyModal({
  open,
  onClose,
  isToValidateEmail,
  isToValidateWhatsapp,
  isToLogoutOnClose,
  onSuccessWhatsapp,
}) {
  const {
    methods,
    onSubmit,
    requestEmailToken,
    requestWhatsappToken,
    requestEmailTokenSuccessMessage,
    requestWhatsappSuccessMessage,
    emailError,
    whatsappError,
    isLoading,
    updadeCellphoneMutate,
    isLoadingUpdateCellphone,
    whatsappNumber,
  } = useVerifyModal({
    onSuccessWhatsapp,
  });

  const [cellphone, setCellphone] = useState();

  const { logout } = useAuthContext();

  const and = isToValidateEmail && isToValidateWhatsapp ? 'e' : '';
  const email = isToValidateEmail ? 'e-mail ' : '';
  const whatsapp = isToValidateWhatsapp ? 'whatsapp ' : '';

  const handleClose = () => {
    onClose();
    if (isToLogoutOnClose) {
      logout();
    }
  };

  const onChangePhone = (value, info) => {
    methods.clearErrors('cellphone');
    setCellphone(value);
    const { countryCallingCode, nationalNumber } = info;
    methods.setValue('phoneCountryCode', countryCallingCode);
    methods.setValue('cellphone', nationalNumber);
  };

  useEffect(() => {
    methods.setValue('isToValidateEmail', isToValidateEmail);
    methods.setValue('isToValidateWhatsapp', isToValidateWhatsapp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isToValidateEmail, isToValidateWhatsapp]);

  const error = useMemo(() => {
    const entries = Object.entries(methods.formState.errors);

    const [, emailEntrie] = entries.find(([type]) => type.includes('email')) || [null, null];
    const [, whatsappEntrie] = entries.find(([type]) => type.includes('whatsapp')) || [null, null];

    return {
      email: emailEntrie?.message || emailError,
      whatsapp: whatsappEntrie?.message || whatsappError,
    };
  }, [methods.formState.errors, emailError, whatsappError]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <FormProvider methods={methods}>
        <Stack p={2} gap={2}>
          <Typography variant="h6">
            Verifique seu {email} {and} {whatsapp}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            <strong>
              Enviamos um código de verificação para o seu {email} {and} {whatsapp}{' '}
              {!!whatsappNumber && !!whatsapp && `(${formatPhoneNumber(whatsappNumber)})`}. Por
              favor, verifique suas mensagens e insira o código abaixo para validar sua conta.
            </strong>
          </Typography>

          <Stack gap={4}>
            {isToValidateEmail && (
              <Stack gap={1}>
                <Typography ml={10} variant="caption" color="text.secondary">
                  <strong>Código de verificação enviado para o seu e-mail</strong>
                </Typography>
                <RHFCodes
                  keyName="email"
                  inputs={['email1', 'email2', 'email3', 'email4', 'email5', 'email6']}
                />
                <FormHelperText
                  data-testid="email-code-error-message"
                  error
                  sx={{ marginLeft: 10, marginTop: 0 }}
                >
                  {error.email}
                </FormHelperText>

                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ marginLeft: 10, marginTop: 0 }}
                >
                  Não tem um código?{' '}
                  <Button
                    component="span"
                    sx={{
                      color: '#4caf50',
                      cursor: 'pointer',
                      textDecoration: 'none',
                      backgroundColor: 'transparent',
                      '&:hover': {
                        textDecoration: 'underline',
                        backgroundColor: 'transparent',
                      },
                    }}
                    onClick={requestEmailToken}
                    disabled={isLoading}
                  >
                    Reenviar código
                  </Button>
                </Typography>

                {!!requestEmailTokenSuccessMessage?.length && (
                  <Alert
                    sx={{
                      width: {
                        xs: '100%',
                        sm: '80%',
                      },
                      mx: 'auto',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="success"
                  >
                    {requestEmailTokenSuccessMessage}
                  </Alert>
                )}
              </Stack>
            )}
            {isToValidateWhatsapp && (
              <Stack gap={1}>
                <Typography
                  sx={{ marginLeft: { xs: null, sm: 10 }, marginTop: 0 }}
                  variant="caption"
                  color="text.secondary"
                >
                  <strong>Código de verificação enviado para o seu whatsapp</strong>
                </Typography>
                <RHFCodes
                  keyName="whatsapp"
                  inputs={[
                    'whatsapp1',
                    'whatsapp2',
                    'whatsapp3',
                    'whatsapp4',
                    'whatsapp5',
                    'whatsapp6',
                  ]}
                />

                <FormHelperText
                  data-testid="whatsapp-code-error-message"
                  error
                  sx={{ marginLeft: 10, marginTop: 0 }}
                >
                  {error.whatsapp}
                </FormHelperText>

                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ marginLeft: { xs: null, sm: 10 }, marginTop: 0 }}
                >
                  Não tem um código?{' '}
                  <Button
                    component="span"
                    sx={{
                      color: '#4caf50',
                      cursor: 'pointer',
                      textDecoration: 'none',
                      backgroundColor: 'transparent',
                      '&:hover': {
                        textDecoration: 'underline',
                        backgroundColor: 'transparent',
                      },
                    }}
                    disabled={isLoading}
                    onClick={requestWhatsappToken}
                  >
                    Reenviar código
                  </Button>
                </Typography>

                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ marginLeft: { xs: null, sm: 10 }, marginTop: 0 }}
                >
                  Não reconhece ou não tem mais este número de Whatsapp?{' '}
                </Typography>
                <Stack gap={1} flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="center">
                  <MuiTelInput
                    size="small"
                    placeholder="(00) 00000-0000"
                    onlyCountries={['BR', 'PT']}
                    defaultCountry="BR"
                    autoFocus
                    name="cellphone"
                    type="phone"
                    InputLabelProps={{ shrink: true }}
                    value={cellphone}
                    onChange={onChangePhone}
                    focusPosition="end"
                    forceCallingCode
                    disableDropdown={false}
                    error={!!methods?.formState?.errors?.cellphone}
                    helperText={methods?.formState?.errors?.cellphone?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Iconify icon="logos:whatsapp-icon" sx={{ color: 'success.main' }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <LoadingButton
                    variant="contained"
                    type="button"
                    onClick={updadeCellphoneMutate}
                    loading={isLoadingUpdateCellphone}
                  >
                    Atualizar
                  </LoadingButton>
                </Stack>
                {!!requestWhatsappSuccessMessage?.length && (
                  <Alert
                    sx={{
                      width: {
                        xs: '100%',
                        sm: '80%',
                      },
                      mx: 'auto',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="success"
                  >
                    {requestWhatsappSuccessMessage}
                  </Alert>
                )}
              </Stack>
            )}

            <Stack
              flexDirection={{ xs: 'column-reverse', sm: 'row' }}
              alignItems="center"
              justifyContent="flex-end"
              gap={1}
            >
              <Button
                fullWidth
                sx={{ width: { xs: '100%', sm: 'auto' } }}
                variant="outlined"
                onClick={handleClose}
                color="inherit"
              >
                Cancelar
              </Button>
              <LoadingButton
                variant="contained"
                type="button"
                onClick={onSubmit}
                loading={isLoading}
                fullWidth
                sx={{ width: { xs: '100%', sm: 'auto' } }}
              >
                Confirmar Código
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>
      </FormProvider>
    </Dialog>
  );
}
