import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  isToValidateEmail: Yup.boolean(),
  isToValidateWhatsapp: Yup.boolean(),

  email1: Yup.number().when('isToValidateEmail', {
    is: true,
    then: Yup.number()
      .typeError('Favor insira o código correto')
      .required('Favor insira o código correto'),
  }),
  email2: Yup.number().when('isToValidateEmail', {
    is: true,
    then: Yup.number()
      .typeError('Favor insira o código correto')
      .required('Favor insira o código correto'),
  }),
  email3: Yup.number().when('isToValidateEmail', {
    is: true,
    then: Yup.number()
      .typeError('Favor insira o código correto')
      .required('Favor insira o código correto'),
  }),
  email4: Yup.number().when('isToValidateEmail', {
    is: true,
    then: Yup.number()
      .typeError('Favor insira o código correto')
      .required('Favor insira o código correto'),
  }),
  email5: Yup.number().when('isToValidateEmail', {
    is: true,
    then: Yup.number()
      .typeError('Favor insira o código correto')
      .required('Favor insira o código correto'),
  }),
  email6: Yup.number().when('isToValidateEmail', {
    is: true,
    then: Yup.number()
      .typeError('Favor insira o código correto')
      .required('Favor insira o código correto'),
  }),

  whatsapp1: Yup.number().when('isToValidateWhatsapp', {
    is: true,
    then: Yup.number()
      .typeError('Favor insira o código correto')
      .required('Favor insira o código correto'),
  }),
  whatsapp2: Yup.number().when('isToValidateWhatsapp', {
    is: true,
    then: Yup.number()
      .typeError('Favor insira o código correto')
      .required('Favor insira o código correto'),
  }),
  whatsapp3: Yup.number().when('isToValidateWhatsapp', {
    is: true,
    then: Yup.number()
      .typeError('Favor insira o código correto')
      .required('Favor insira o código correto'),
  }),
  whatsapp4: Yup.number().when('isToValidateWhatsapp', {
    is: true,
    then: Yup.number()
      .typeError('Favor insira o código correto')
      .required('Favor insira o código correto'),
  }),
  whatsapp5: Yup.number().when('isToValidateWhatsapp', {
    is: true,
    then: Yup.number()
      .typeError('Favor insira o código correto')
      .required('Favor insira o código correto'),
  }),
  whatsapp6: Yup.number().when('isToValidateWhatsapp', {
    is: true,
    then: Yup.number()
      .typeError('Favor insira o código correto')
      .required('Favor insira o código correto'),
  }),
});
