import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import styles from './styles.module.css';

InformPendingRegistration.propTypes = {
  open: PropTypes.bool,
};

export const InfoRegistration = {
  STATUS: 'info-registration-displayed',
  DISPLAYED: 'DISPLAYED',
};

export function InformPendingRegistration({ open }) {
  const navigate = useNavigate();
  const redirectToIdentityFinance = () => {
    navigate('/dashboard/financial');
    localStorage.setItem(InfoRegistration.STATUS, InfoRegistration.DISPLAYED);
  };

  const title = <strong>Seu cadastro está incompleto.</strong>;
  const content = (
    <span>
      Por favor, para conseguir começar a fazer suas vendas, complete seu registro na aba
      Financeiro/Identidade ou clique no botão Completar abaixo.
    </span>
  );
  const message = (
    <>
      {title} {content}
    </>
  );

  return (
    <Snackbar
      className={styles.snack_container}
      open={open}
      message={message}
      action={
        <Button variant="contained" size="small" onClick={redirectToIdentityFinance}>
          Completar
        </Button>
      }
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    />
  );
}
