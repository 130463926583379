import { useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function useActiveLink(path, deep = true) {
  const { pathname } = useLocation();

  const getPathname = (url) => {
    try {
      return new URL(url, window.location.origin).pathname;
    } catch (error) {
      return url;
    }
  };

  const normalizedPath = getPathname(path);
  const normalActive = normalizedPath === pathname;

  const deepActive = pathname.startsWith(normalizedPath);

  return {
    active: deep ? deepActive : normalActive,
    isExternalLink: path.includes('http'),
  };
}
