import * as datefns from 'date-fns';
import { v4 } from 'uuid';
import * as yup from 'yup';

export const CHECKOUT_BUILDER_DRAWER_WIDTH = 360;

export const CHECKOUT_BUILDER_DRAWER_HEIGHT = 250;

export const CHECKOUT_BUILDER_DESKTOP_ROW_LAYOUTS = [[12], [8, 4], [4, 8], [4, 4, 4]];

export const CHECKOUT_BUILDER_MOBILE_ROW_LAYOUTS = [[12]];

export const CheckoutBuilderFontFamilies = ['Roboto', 'Open Sans'];

export const ThemeModesOptions = [
  {
    value: 'custom',
    label: 'Customizado',
  },
  {
    value: 'light',
    label: 'Claro',
  },
  {
    value: 'dark',
    label: 'Escuro',
  },
];

export const CheckoutBuilderDeviceTypes = {
  desktop: {
    id: 'desktop',
    name: 'Desktop',
    icon: 'ion:desktop-outline',
    layouts: CHECKOUT_BUILDER_DESKTOP_ROW_LAYOUTS,
  },
  mobile: {
    id: 'mobile',
    name: 'Mobile',
    icon: 'ion:phone-portrait-outline',
    layouts: CHECKOUT_BUILDER_MOBILE_ROW_LAYOUTS,
  },
};

export const lightThemeValues = {
  background: {
    color: '#f1f1f1',
  },
  form: {
    background: {
      color: '#ffffff',
    },
  },
  font: {
    color: {
      primary: '#000000',
      secondary: '#4B5563',
    },
  },
  icon: {
    color: '#000000',
  },
  paymentOptions: {
    button: {
      unselected: {
        text: {
          color: '#4B5563',
        },
        background: {
          color: '#ffffff',
        },
        icon: {
          color: '#000000',
        },
      },
      selected: {
        text: {
          color: '#ffffff',
        },
        background: {
          color: '#0B6856',
        },
        icon: {
          color: '#ffffff',
        },
      },
    },
  },
  payButton: {
    text: {
      text: 'PAGAR AGORA',
      color: '#ffffff',
    },
    color: '#0B6856',
  },
};

export const darkThemeValues = {
  background: {
    color: '#161C24',
  },
  form: {
    background: {
      color: '#212B36',
    },
  },
  font: {
    color: {
      primary: '#ffffff',
      secondary: '#9CA3AF',
    },
  },
  icon: {
    color: '#ffffff',
  },
  paymentOptions: {
    button: {
      unselected: {
        text: {
          color: '#9CA3AF',
        },
        background: {
          color: '#212B36',
        },
        icon: {
          color: '#ffffff',
        },
      },
      selected: {
        text: {
          color: '#ffffff',
        },
        background: {
          color: '#0B6856',
        },
        icon: {
          color: '#ffffff',
        },
      },
    },
  },
  payButton: {
    text: {
      text: 'PAGAR AGORA',
      color: '#ffffff',
    },
    color: '#0B6856',
  },
};

export const CheckoutBuilderComponentTypes = {
  text: {
    id: 'text',
    type: 'text',
    name: 'Texto',
    icon: 'ion:text-outline',
    done: true,
    attributes: {
      backgroundColor: '#808080',
      borderColor: '#404040',
      text: '<p><span style="font-size: 64px; color: #ffffff;">Digite seu texto aqui</span></p>',
      color: '#ffffff',
      borderWidth: 1,
      borderRadius: 1,
    },
    schema: yup.object().shape({
      backgroundColor: yup.string().required('Obrigatório'),
      borderColor: yup.string().required('Obrigatório'),
      text: yup.string().required('Obrigatório'),
      borderWidth: yup
        .number()
        .typeError('A largura da borda do texto é obrigatória')
        .required('A largura da borda do texto é obrigatória'),
      borderRadius: yup
        .number()
        .typeError('O raio da da borda do texto é obrigatório')
        .required('O raio da da borda do texto é obrigatório'),
    }),
  },
  image: {
    id: 'image',
    type: 'image',
    name: 'Imagem',
    icon: 'bxs:image',
    done: true,
    attributes: {
      image: '',
      alignment: 'center',
      redirectUrl: '',
      width: null,
    },
    schema: yup.object().shape({
      image: yup.mixed().required('A imagem é obrigatória'),
      alignment: yup.string().required('O alinhamento da imagem é obrigatório'),
      redirectUrl: yup.string(),
      width: yup.number().nullable(),
    }),
  },
  advantage: {
    id: 'advantage',
    type: 'advantage',
    name: 'Vantagens',
    icon: 'ion:checkmark-done',
    done: true,
    attributes: {
      title: 'Garantia de 7 dias',
      subtitle: 'Experimente sem riscos',
      icon: 'verified',
      primaryColor: '#0f7865',
      titleTextColor: '#000000',
      size: 'original',
      darkMode: false,
      vertical: false,
    },
    schema: yup.object().shape({
      icon: yup.string().required('O ícone das Vantagens é obrigatório'),
      primaryColor: yup.string().required('A cor principal das Vantagens é obrigatória'),
      titleTextColor: yup.string().required('A cor do título das Vantagens é obrigatória'),
      size: yup.string().required('O tamanho das vantagnes é obrigatório'),
      darkMode: yup.boolean().required('A escolha do modo escuro das Vantagens é obrigatória'),
      vertical: yup.boolean().required('A escolha do modo vertical das Vantagens é obrigatória'),
    }),
  },
  seal: {
    id: 'seal',
    type: 'seal',
    name: 'Selo',
    icon: 'ion:ribbon',
    done: true,
    attributes: {
      type: 'one',
      title: 'Privacidade',
      subtitle: 'Garantida',
      primaryColor: '#4593f9',
      titleTextColor: '#fff',
      alignment: 'center',
      darkMode: false,
      width: 250,
    },
    schema: yup.object().shape({
      type: yup.string().required('O tipo do Selo é obrigatório'),
      title: yup.string().required('O título do Selo é obrigatório'),
      subtitle: yup.string().required('O subtítulo do Selo é obrigatório'),
      primaryColor: yup.string().required('A cor primária do Selo é obrigatória'),
      titleTextColor: yup.string().required('A cor do título do Selo é obrigatória'),
      alignment: yup.string().required('O alihamento so Selo é obrigatório'),
      darkMode: yup.boolean().required('A escolha do tema escuro do Selo é obrigatória'),
      width: yup.number().required('A largura do Selo é obrigatória'),
    }),
  },
  header: {
    id: 'header',
    type: 'header',
    name: 'Header',
    icon: 'uis:web-grid-alt',
    done: true,
    attributes: {
      backgroundType: 'image',
      backgroundColor: '#ffffff',
      backgroundImage: '',
      productImageType: 'same',
      productImage: '',
      productImageAlignment: 'left',
      titleFontSize: 24,
      titleTextColor: '#000000',
      titleText: 'Nome do seu produto',
      showSubtitle: false,
      subtitleFontSize: 14,
      subtitleTextColor: '#000000',
      subtitleText: 'Escreva um subtítulo aqui',
    },
    schema: yup.object().shape({
      backgroundType: yup.string().required('O tipo background do Header é obrigatório'),
      backgroundColor: yup.string().required('A cor do background do Header é obrigatório'),
      backgroundImage: yup
        .mixed()
        .required('A cor do background da imagem do Header é obrigatória'),
      productImageType: yup
        .string()
        .required('O tipo da imagem do produto do Header é obrigatório'),
      productImage: yup.mixed().required('A imagem do produto do Header é obrigatório'),
      productImageAlignment: yup
        .string()
        .required('O alinhamento da imagem do produto do Header é obrigatório'),
      titleFontSize: yup
        .number()
        .typeError('O tamanho do título do Header é obrigatório')
        .required('O tamanho do título do Header é obrigatório'),
      titleTextColor: yup.string().required('A cor do texto do título do Header é obrigatória'),
      titleText: yup.string().required('O título do texto do Header é obrigatório'),
      showSubtitle: yup
        .boolean()
        .required('A escolha de mostrar o subtítulo do header é obrigatória'),
      subtitleFontSize: yup
        .number()
        .typeError('O tamanho do subtítulo do Header é obrigatório')
        .required('O tamanho do subtítulo do Header é obrigatório'),
      subtitleTextColor: yup
        .string()
        .required('A cor do subtítulo do texto do Header é obrigatória'),
      subtitleText: yup.string().required('O subtítulo do Header é obrigatório'),
    }),
  },
  list: {
    id: 'list',
    type: 'list',
    name: 'Lista',
    icon: 'majesticons:list-box',
    done: true,
    attributes: {
      backgroundColor: '#ffffff',
      textColor: '#000000',
      alignment: 'left',
      fontSize: 16,
      showTitle: false,
      title: 'Título da lista',
      style: 'check',
      iconColor: '#0f7865',
      items: [
        {
          id: v4(),
          text: 'Item 1',
        },
        {
          id: v4(),
          text: 'Item 2',
        },
        {
          id: v4(),
          text: 'Item 3',
        },
      ],
    },
    schema: yup.object().shape({
      backgroundColor: yup.string().required('A cor de fundo da Lista é obrigatória'),
      textColor: yup.string().required('A cor do texto da Lista é obrigatória'),
      alignment: yup.string().required('O alinhamento da Lista é obrigatório'),
      fontSize: yup
        .number()
        .typeError('O tamanho da fonte da Lista é obrigatório')
        .required('O tamanho da fonte da Lista é obrigatório'),
      showTitle: yup.boolean().required('A opção de escolha do título da Lista é obrigatória'),
      title: yup.string().required('O título da Lista é obrigatório'),
      style: yup.string().required('O estilo da lista é obrigatório'),
      iconColor: yup.string().required('A cor do ícone da Lista é obrigatória'),
      items: yup
        .array()
        .of(
          yup.object().shape({
            id: yup.string().required('O id do item da Lista é obrigatório'),
            text: yup.string().required('O texto do item da Lista é obrigatório'),
          })
        )
        .min(1, 'Insira ao menos 1 item na Lista')
        .required('Insira ao menos 1 item na Lista'),
    }),
  },
  countdown: {
    id: 'countdown',
    type: 'countdown',
    name: 'Cronômetro',
    icon: 'icon-park-solid:timer',
    done: true,
    attributes: {
      backgroundColor: '#e55858ff',
      textColor: '#ffffff',
      activeText: 'Oferta por tempo limitado',
      finishedText: 'Oferta finalizada',
      fixedOnTop: false,
      type: 'time',
      time: '15:00',
      date: datefns.format(new Date(), 'yyyy-MM-dd'),
    },
    schema: yup.object().shape({
      backgroundColor: yup.string().required('A cor de fundo do Cronômetro é obrigatória'),
      textColor: yup.string().required('A cor do texto do Cronômetro é obrigatória'),
      activeText: yup.string().required('O texto de contagem ativa do Cronômetro é obrigatório'),
      finishedText: yup
        .string()
        .required('O texto de contagem finalizada do Cronômetro é obrigatório'),
      fixedOnTop: yup.boolean().required('A escolha de fixar no topo o Cronômetro é obrigatória'),
      type: yup.string().required('O tipo do Cronômetro é obrigatório'),
      time: yup.string().required('O tempo do Cronômetro é obrigatório'),
      date: yup.string().required('A data do Cronômetro é obrigatória'),
    }),
  },
  testimonial: {
    id: 'testimonial',
    type: 'testimonial',
    name: 'Depoimento',
    icon: 'material-symbols:reviews',
    done: true,
    attributes: {
      backgroundColor: '#ffffff',
      textColor: '#000000',
      avatar: null,
      author: 'John Doe',
      text: 'Digite seu depoimento aqui',
      rating: 5,
      horizontal: false,
    },
    schema: yup.object().shape({
      backgroundColor: yup.string().required('A cor de fundo do Depoimento é obrigatória'),
      textColor: yup.string().required('A cor do texto do Depoimento é obrigatória'),
      avatar: yup.mixed().required('O avatar do Depoimento é obrigatório'),
      author: yup.string().required('O autor do Depoimento é obrigatório'),
      text: yup.string().required('O texto do Depoimento é obrigatório'),
      rating: yup
        .number()
        .typeError('As estrelas do Depoimento são obrigatórias')
        .required('As estrelas do Depoimento são obrigatórias'),
      horizontal: yup
        .boolean()
        .required('A escolha do modo horizontal do Depoimento é obrigatória'),
    }),
  },
  video: {
    id: 'video',
    type: 'video',
    name: 'Vídeo',
    icon: 'majesticons:video',
    done: true,
    attributes: {
      url: '',
      alignment: 'center',
      width: 300,
      hideControls: false,
    },
    schema: yup.object().shape({
      url: yup
        .string()
        .url('Insira uma url válida para o Vídeo')
        .required('A url do Vídeo é obrigatória'),
      alignment: yup.string().required('O alinhamento do Vídeo é obrigatório'),
      width: yup.number().required('A largura do Vídeo é obrigatória'),
      hideControls: yup
        .boolean()
        .required('A escolha de esconder os controles do Vídeo é obrigatória'),
    }),
  },
  facebook: {
    id: 'facebook',
    type: 'facebook',
    name: 'Facebook',
    icon: 'ic:baseline-facebook',
    done: true,
    attributes: {
      type: 'commentSection',
      url: '',
      size: 'original',
      count: 3,
      orderBy: 'relevant',
      tabs: ['timeline'],
      options: [],
    },
    schema: yup.object().shape({
      type: yup.string().required('O tipo de interação com o Facebook é obrigatório'),
      url: yup
        .string()
        .matches(
          /^(https?:\/\/)?(www\.)?facebook\.com\/.+$/i,
          'Insira uma URL válida para o Facebook'
        )
        .required('A url do Facebook é obrigatória'),
      size: yup.string().required('O tamanho do componente do Facebook é obrigatório'),
      tabs: yup.array().when('type', {
        is: 'page',
        then: yup
          .array()
          .min(1, 'As tabs são obrigatórias no Facebook e devem conter ao menos um elemento')
          .required('As tabs são obrigatórias no Facebook'),
        otherwise: yup.array(),
      }),
      options: yup.array().when('type', {
        is: 'page',
        then: yup
          .array()
          .min(1, 'As opções do Facebook são obrigatórias e devem conter ao menos um elemento')
          .required('As opções do Facebook são obrigatórias'),
        otherwise: yup.array(),
      }),
      count: yup.number().when('type', {
        is: 'commentSection',
        then: yup
          .number()
          .typeError('A quantidade de comentários de Facebook é obrigatória')
          .required('A quantidade de comentários de Facebook é obrigatória'),
        otherwise: yup
          .number()
          .nullable()
          .transform((value, originalValue) => (originalValue === '' ? null : value)),
      }),
      orderBy: yup.string().when('type', {
        is: 'commentSection',
        then: yup.string().required('A ordenação do Facebook é obrigatória'),
        otherwise: yup.string(),
      }),
    }),
  },
  map: {
    id: 'map',
    type: 'map',
    name: 'Mapa',
    icon: 'mdi:location',
    done: true,
    attributes: {
      address: '',
      alignment: 'center',
      width: 300,
    },
    schema: yup.object().shape({
      address: yup.string().required('O endereço do Mapa é obrigatório'),
      alignment: yup.string().required('O alinhamento do Mapa é obrigatório'),
      width: yup.number().required('A largura do Mapa é obrigatória'),
    }),
  },
  exitPopup: {
    id: 'exitPopup',
    type: 'exitPopup',
    name: 'Exit Popup',
    icon: 'fluent:form-multiple-28-filled',
    extra: true,
    done: true,
    devices: [CheckoutBuilderDeviceTypes.desktop.id, CheckoutBuilderDeviceTypes.mobile.id],
    attributes: {
      enabled: false,
      type: 'image',
      actionOnClick: 'offer',
      offer: null,
      title: 'Temos uma oferta especial para você!',
      description: 'Escreva algo aqui para convencer o cliente a não sair da página',
      actionLabel: 'Sim, eu quero!',
      image: null,
      video: null,
      url: null,
      backgroundButtonColor: '#0f7865',
      textButtonColor: '#ffffff',
    },
    schema: yup.object().shape({
      enabled: yup.boolean().required('A opção para habilitar o Exit Popup é obrigatória'),
      type: yup.string().when('enabled', {
        is: true,
        then: yup.string().required('O tipo do Exit Popup é obrigatório'),
      }),
      actionOnClick: yup.string().when('enabled', {
        is: true,
        then: yup.string().required('A ação ao clicar no Exit Popup é obrigatória'),
      }),
      offer: yup
        .string()
        .nullable()
        .when(['enabled', 'actionOnClick'], {
          is: (enabled, actionOnClick) => enabled && actionOnClick === 'offer',
          then: yup
            .string()
            .required('A oferta do Exit Popup é obrigatória')
            .typeError('A oferta do Exit Popup deve ser um texto'),
        }),
      coupon: yup
        .string()
        .nullable()
        .when(['enabled', 'actionOnClick'], {
          is: (enabled, actionOnClick) => enabled && actionOnClick === 'discount',
          then: yup
            .string()
            .required('O cupom do Exit Popup é obrigatório')
            .typeError('O cupom do Exit Popup deve ser um texto'),
        }),

      title: yup.string().when(['enabled', 'type'], {
        is: (enabled, type) => enabled && type === 'imageAndText',
        then: yup
          .string()
          .required('O título do Exit Popup é obrigatório')
          .typeError('O título do Exit Popup deve ser um texto'),
      }),
      description: yup.string().when(['enabled', 'type'], {
        is: (enabled, type) => enabled && type === 'imageAndText',
        then: yup
          .string()
          .required('A descrição do Exit Popup é obrigatória')
          .typeError('A descrição do Exit Popup deve ser um texto'),
      }),
      actionLabel: yup.string().when('enabled', {
        is: true,
        then: yup.string().required('O texto do botão do Exit Popup é obrigatório'),
      }),
      image: yup.mixed().when(['enabled', 'type'], {
        is: (enabled, type) => enabled && type === 'image',
        then: yup.mixed().required('A imagem do Exit Popup é obrigatória'),
      }),
      video: yup
        .string()
        .url('Por favor, insira uma url válida no vídeo do Exit Popup')
        .nullable()
        .when(['enabled', 'type'], {
          is: (enabled, type) => enabled && type === 'video',
          then: yup
            .string()
            .required('O vídeo do Exit Popup é Obrigatório')
            .url('Por favor, insira uma URL válida no vídeo do Exit Popup')
            .typeError('Por favor, insira uma URL válida no vídeo do Exit Popup'),
        }),
      url: yup
        .string()
        .nullable()
        .when(['enabled', 'actionOnClick'], {
          is: (enabled, actionOnClick) => enabled && actionOnClick === 'redirect',
          then: yup
            .string()
            .required('A URL do Exit Popup é obrigatória')
            .typeError('O valor da URL do Exit Popup deve ser um URL válido'),
        }),
    }),
  },
  notification: {
    id: 'notification',
    type: 'notification',
    name: 'Notificação',
    icon: 'basil:notification-on-solid',
    extra: true,
    done: true,
    devices: [CheckoutBuilderDeviceTypes.desktop.id],
    attributes: {
      enabled: false,
      interestedLast24Hours: {
        enabled: false,
        value: 0,
      },
      interestedLastWeek: {
        enabled: false,
        value: 0,
      },
      interestedRightNow: {
        enabled: false,
        value: 0,
      },
      purchasedLast24Hours: {
        enabled: false,
        value: 0,
      },
      purchasedLastWeek: {
        enabled: false,
        value: 0,
      },
    },
    schema: yup.object().shape({
      enabled: yup.boolean().required('A opção para habilitar as Notificações é obrigatória'),
      interestedLast24Hours: yup.object().when('enabled', {
        is: true,
        then: yup.object().shape({
          enabled: yup.boolean().required('Obrigatório'),
          min: yup.number().when('enabled', {
            is: true,
            then: yup
              .number()
              .required('O valor mínimo da Notificação é obrigatório')
              .typeError('O valor mínimo da Notificação deve ser um número'),
          }),
          max: yup.number().when('enabled', {
            is: true,
            then: yup
              .number()
              .required('O valor máximo da Notificação é obrigatório')
              .typeError('O valor máximo da Notificação deve ser um número')
              .test(
                'is-greater',
                'O valor máximo da Notificação deve ser maior ou igual ao valor mínimo da mesma',
                function val(value) {
                  const { min } = this.parent;
                  return value >= min;
                }
              ),
          }),
        }),
      }),
      interestedLastWeek: yup.object().when('enabled', {
        is: true,
        then: yup.object().shape({
          enabled: yup.boolean().required('Obrigatório'),
          min: yup.number().when('enabled', {
            is: true,
            then: yup
              .number()
              .required('O valor mínimo da Notificação é obrigatório')
              .typeError('O valor mínimo da Notificação deve ser um número'),
          }),
          max: yup.number().when('enabled', {
            is: true,
            then: yup
              .number()
              .required('O valor máximo da Notificação é obrigatório')
              .typeError('O valor máximo da Notificação deve ser um número')
              .test(
                'is-greater',
                'O valor máximo da Notificação deve ser maior ou igual ao valor mínimo da mesma',
                function val(value) {
                  const { min } = this.parent;
                  return value >= min;
                }
              ),
          }),
        }),
      }),
      interestedRightNow: yup.object().when('enabled', {
        is: true,
        then: yup.object().shape({
          enabled: yup.boolean().required('Obrigatório'),
          min: yup.number().when('enabled', {
            is: true,
            then: yup
              .number()
              .required('O valor mínimo da Notificação é obrigatório')
              .typeError('O valor mínimo da Notificação deve ser um número'),
          }),
          max: yup.number().when('enabled', {
            is: true,
            then: yup
              .number()
              .required('O valor máximo da Notificação é obrigatório')
              .typeError('O valor máximo da Notificação deve ser um número')
              .test(
                'is-greater',
                'O valor máximo da Notificação deve ser maior ou igual ao valor mínimo da mesma',
                function val(value) {
                  const { min } = this.parent;
                  return value >= min;
                }
              ),
          }),
        }),
      }),
      purchasedLast24Hours: yup.object().when('enabled', {
        is: true,
        then: yup.object().shape({
          enabled: yup.boolean().required('Obrigatório'),
          min: yup.number().when('enabled', {
            is: true,
            then: yup
              .number()
              .required('O valor mínimo da Notificação é obrigatório')
              .typeError('O valor mínimo da Notificação deve ser um número'),
          }),
          max: yup.number().when('enabled', {
            is: true,
            then: yup
              .number()
              .required('O valor máximo da Notificação é obrigatório')
              .typeError('O valor máximo da Notificação deve ser um número')
              .test(
                'is-greater',
                'O valor máximo da Notificação deve ser maior ou igual ao valor mínimo da mesma',
                function val(value) {
                  const { min } = this.parent;
                  return value >= min;
                }
              ),
          }),
        }),
      }),
      purchasedLastWeek: yup.object().when('enabled', {
        is: true,
        then: yup.object().shape({
          enabled: yup.boolean().required('Obrigatório'),
          min: yup.number().when('enabled', {
            is: true,
            then: yup
              .number()
              .required('O valor mínimo da Notificação é obrigatório')
              .typeError('O valor mínimo da Notificação deve ser um número'),
          }),
          max: yup.number().when('enabled', {
            is: true,
            then: yup
              .number()
              .required('O valor máximo da Notificação é obrigatório')
              .typeError('O valor máximo da Notificação deve ser um número')
              .test(
                'is-greater',
                'O valor máximo da Notificação deve ser maior ou igual ao valor mínimo da mesma',
                function val(value) {
                  const { min } = this.parent;
                  return value >= min;
                }
              ),
          }),
        }),
      }),
    }),
  },
  chat: {
    id: 'chat',
    type: 'chat',
    name: 'Chat',
    icon: 'fluent:chat-24-filled',
    extra: true,
    done: true,
    devices: [CheckoutBuilderDeviceTypes.desktop.id, CheckoutBuilderDeviceTypes.mobile.id],
    attributes: {
      enabled: false,
      provider: 'whatsapp',
      accountId: '',
    },
    schema: yup.object().shape({
      enabled: yup.boolean().required('A opção para habilitar o Chat é obrigatória'),
      provider: yup.string().when('enabled', {
        is: true,
        then: yup.string().required('O provedor do Chat é obrigatório'),
      }),
      accountId: yup.string().when('enabled', {
        is: true,
        then: yup.string().required('As informações do Chat são obrigatórias'),
      }),
    }),
  },
  row: {
    id: 'row',
    type: 'row',
    name: 'Linha',
    icon: 'carbon:row',
    attributes: {},
  },
  checkout: {
    id: 'checkout',
    type: 'checkout',
    name: 'Checkout',
    icon: 'icon-park-solid:shopping',
    attributes: {},
    config: {
      settings: {
        ...lightThemeValues,
      },
    },
  },
};
